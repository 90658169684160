<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">List course</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDialogCourse"
        v-if="canAccess('course-create')"
      >
        <v-icon color="white" dark>mdi-plus</v-icon>
        <span style="color: white">Add Course</span>
      </v-btn>

      <router-link :to="{name: 'AnnouncementList'}">
        <v-btn
          color="primary"
          class="mb-5 ml-2"
          x-small
          large
        >
          <v-icon color="white" dark>mdi-dots-vertical</v-icon>
          <v-icon color="white" style="margin-left: -18px" dark>mdi-dots-vertical</v-icon>
          <span style="color: white">Danh sách thông báo</span>
        </v-btn>
      </router-link>


      <div>
        <v-row>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search Name (Enter để tìm kiếm)"
              v-model="data_search"
              @keyup.enter="actionSearchCourse()"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Cover</th>
                    <th scope="col">Level</th>
                    <th scope="col">Teachers</th>
                    <th scope="col">Total Hours</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(course, i) in courses">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ course.code }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          <template v-if="course?.name?.vi">
                            {{ course.name.vi }}
                          </template>
                          <template v-else-if="course?.name?.en">
                            {{ course.name.en }}
                          </template>
                          <template v-else>
                            (none)
                          </template>
                        </p>
                      </td>
                      <td>
                        <v-img
                          v-if="course.cover"
                          :src="course.cover.vi"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >{{ course.entry_level }}</span
                          >
                          <v-icon class="ml-2"
                            >mdi-arrow-right-bold-outline</v-icon
                          >
                          <span
                            class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                            >{{ course.end_of_course_level }}</span
                          >
                        </p>
                      </td>
                      <td class="d-flex align-items-center">
                        <template v-for="(teacher, i) in course.teachers.data">
                          <v-tooltip bottom :key="i" color="#faebd7">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="d-flex align-items-center"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div class="symbol symbol-50 symbol-light mr-4">
                                  <span class="symbol-label">
                                    <img
                                      src="media/users/default.jpg"
                                      class="h-75 align-self-end"
                                    />
                                  </span>
                                </div>
                              </div>
                            </template>
                            <div>
                              <p
                                class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                              >
                                {{ teacher.name }}
                              </p>
                              <span
                                class="text-muted font-weight-bold d-block"
                                >{{ teacher.email }}</span
                              >
                            </div>
                          </v-tooltip>
                        </template>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ course.total_hours }}
                        </p>
                      </td>
                      <td>
                        <span
                          class="label label-lg label-inline font-weight-bold py-4"
                          v-bind:class="
                            course.is_locked === 0
                              ? 'label-light-success'
                              : 'label-light-warning'
                          "
                        >
                          {{ course.is_locked === 0 ? "Unlock" : "Locked" }}
                        </span>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="detailCourse(i)"
                                v-if="canAccess('course-update')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit Course</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteCourse(course.id)"
                                v-if="canAccess('course-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete Course</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'CourseContent',
                                  params: {
                                    id: course.id,
                                    courseName: course.name.en,
                                  },
                                }"
                              >
                                <button
                                  class="btn btn-icon btn-light-primary btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-eye-settings</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Course Items</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!courses.length">
                    <tr>
                      <td style="text-align: center" colspan="11">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <CourseForm
      :show_dialog="dialogCourse"
      @setDialog="(e) => (this.dialogCourse = e)"
      @resetList="resetListViaActionForm()"
      :type_form="type_form_course"
      :tags="tags"
      :teacher_tags="teacher_tags"
      :course="course"
    />

  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import { validationMixin } from "vuelidate";
import handle_ls_search from "@/mixins/handle_ls_search.js";
export default {
  name: "CourseList",
  mixins: [validationMixin, handle_ls_search],
  components: {
    Loading: () => import("vue-loading-overlay"),
    CourseForm: () => import("@/view/pages/courses/modal/CourseForm"),
  },
  data() {
    return {
      is_call_api: false,
      dialogCourse: false,
      type_form_course: null,
      teacher_tags: [],
      data_search: "",
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      courses: [],
      course: {},
      tags: [],
      timer_query: null,
    };
  },
  computed: {

  },
  watch: {
    "data_pagination.current_page": function (page) {
      this.getAllCourse(page, this.data_search);
    },
    // data_search(val) {
    //   this.actionSearchCourse(val);
    // },
  },
  created() {
    if (this.canAccess("course-view")) {
      this.is_call_api = true;
      let data_search = this.getDataSearchByKey("course");
      if (data_search) {
        this.data_search = data_search.query ? data_search.query : "";
      }
      this.getAllTeacher();
      this.getAllCourse();
      this.getAllTag();
      this.is_call_api = false;
    } else {
      this.$router.push({ name: "403" });
    }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllTag() {
      try {
        let res = await ApiService.get("prep-app/tag/limit");
        if (res.status == 200) {
          this.tags = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllTeacher() {
      let self = this;
      await ApiService.get("prep-app/teachers").then(function (response) {
        if (response.status === 200) {
          self.teacher_tags = response.data;
          //console.log(response);
        }
      });
    },
    async getAllCourse(page = 1, query = "") {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/courses?page=" + page + "&query=" + query
      ).then(function (response) {
        if (response.status === 200) {
          vm.courses = response.data.data;
          vm.data_pagination = response.data.meta.pagination;
          vm.is_call_api = false;
        }
      });
    },
    openDialogCourse() {
      this.dialogCourse = true;
      this.type_form_course = "add";
    },
    detailCourse(i) {
      this.dialogCourse = true;
      this.course = this.courses[i];
      this.type_form_course = "update";
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    deleteCourse(id_course) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/courses/" + id_course)
            .then(function (res) {
              if ([202,200].includes(res.status)) {
                vm.getAllCourse(vm.data_pagination.current_page);
                vm.$toasted.success("Xóa thành công", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    resetListViaActionForm() {
      if (this.type_form_course === 'update') {
        this.getAllCourse(this.data_pagination.current_page);
      }
      if (this.type_form_course === 'add') {
        if (this.data_pagination.current_page === this.data_pagination.total_pages) {
          if (this.data_pagination.count > this.data_pagination.per_page) {
            this.getAllCourse(this.data_pagination.current_page);
          } else {
            this.getAllCourse(this.data_pagination.current_page +1);
          }
        } else {
          this.getAllCourse(this.data_pagination.total_pages);
        }

      }
    },
    actionSearchCourse() {
      let query = this.data_search;
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(function () {
        let course = {
          query: query,
        };
        vm.saveDataSearchToLs("course", course);
        vm.getAllCourse(1, query);
      }, 500);
    }
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.avatar-course {
  width: 100%;
}
.error-validation {
  color: red;
}
.table td {
  vertical-align: middle;
}
.email-teacher {
  font-style: italic;
  font-weight: bold;
  color: gray;
}
.description-teacher {
  max-width: 150px;
  max-height: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.v-image__image {
  border-radius: 7% !important;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
